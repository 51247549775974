import Card from 'react-bootstrap/Card';
import database from "../../data/database.json";
import { isMobile } from 'react-device-detect';
import mds from '../../images/mds.jpeg';

const profileData = database.profile;

const Profile = () => {
    return (
        <div className='profile'>
            <div className="container">
                <div className="row">
                    <div className={`${isMobile ? "col-12" : "col-4"} image-col`}>
                        <div className="about-image">
                            <Card.Img variant="top" src={mds} className='flipImage gray rounded-circle' />
                        </div>
                    </div>
                    <div className={`${isMobile ? "col-12" : "col-8"}`}>
                        <Card.Body className='card-body-about'>
                            <Card.Title>{profileData.name}</Card.Title>
                            <Card.Subtitle>{profileData.title}</Card.Subtitle>
                            {profileData.about.map((entity, index) => {
                                return <Card.Text>{entity.text}</Card.Text>
                            })}
                        </Card.Body>
                    </div>
                </div>
            </div>

        </div >
    );
}
export default Profile;