//import A1 from "../images/A (15).png";

import "./video.css";

import { Work } from "../work/work";

const Video = () => {
    // const data = [{
    //     src: "https://player.vimeo.com/video/570940070?h=389d280506"
    // }]
    return <div className="video" id="kaamkaaj">
        <div className="video-heading">
            <div>KAAMKAAJ</div>
        </div>
        <Work />
    </div>
}
export default Video;