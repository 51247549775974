import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { isMobile } from 'react-device-detect';

export default class Dialog extends React.Component<any, any>{
    render() {
        return (
            <Modal
                size='xl'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show} onHide={this.props.handleClose}>
                {isMobile &&
                    <Modal.Header className='modal-close-header'>
                        <button type="button" aria-label="Close" onClick={this.props.handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                        </button>

                    </Modal.Header>
                }
                <Modal.Body>
                    <iframe title='f9e87254b0' src={this.props.link} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </Modal.Body>
            </Modal>
        );
    }
}