import * as allImages from "../images/images";

import { Card } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import { Header } from "./header/header";
import { Logo } from "./header/logo";
import database from "../data/database.json";
import { iWork } from "./work/commercial";
import { isMobile } from "react-device-detect";
import { useState } from "react";

const data: iWork[] = database.carouselData;
const mobileImage: iWork[] = database.mobileCaraousel;
const mainPageText = database.mainPageText;

const MainPage = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: any, e: any) => {
        setIndex(selectedIndex);
    };

    const allImgs = Object.entries(allImages);

    return <div>
        <Header />
        <div className="carousel-container">
            <div className="new-container">
                <div className="container-upper">
                    <Logo />
                </div>
                <div className="container-lower">
                    <div className="text-container">
                        {mainPageText.map((item, index) => {
                            return <Card.Text id={`${index}`}>{item.text}</Card.Text>
                        })}
                    </div>
                </div>
            </div>
            {!isMobile && <>
                <Carousel interval={3000} activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} fade>
                    {data.map((slide, i) => {
                        const slideImage = allImgs.find((x) => x[0] === slide.image);
                        return (
                            <Carousel.Item>
                                <img className={`${""} d-block w-100 opacity`} src={slideImage?.[1]} alt={slide.caption} />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </>}
            {isMobile && <>
                <Carousel interval={2000} activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} fade>
                    {mobileImage.map((slide, i) => {
                        const slideImage = allImgs.find((x) => x[0] === slide.image);
                        return (
                            <Carousel.Item>
                                <img className="d-block w-100 opacity" src={slideImage?.[1]} alt={slide.caption} />
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </>}
        </div>
    </div>
}
export default MainPage;