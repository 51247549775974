import About from "../components/about/about";
import AllLinks from "../components/contact/allLinks";
import { Commercials } from "../components/work/commercial";
import MainPage from "../components/mainPage";
import React from "react";
import { Shorts } from "../components/work/shorts";
import Video from "../components/videos/videos";

function DashBoard() {
    return (
        <div className="dashboard">
            <MainPage />
            <Video />
            <Commercials />
            <Shorts />
            <About />
            <AllLinks />
        </div>
    );
}

export default DashBoard;