import './about.css';

import Profile from "./profile";

const About = () => {
    return <div className='about' id='about'>
        {heading("ABOUT")}
        <Profile />
    </div>
}
const heading = (headingText: string) => {
    return <div className="about-heading">
        <div>{headingText}</div>
    </div>
}
export default About;